::-webkit-input-placeholder {
  color: #9c9c9c; }

:-moz-placeholder {
  color: #9c9c9c; }

::-moz-placeholder {
  color: #9c9c9c; }

:-ms-input-placeholder {
  color: #9c9c9c; }

.avatar-container {
  width: 50px;
  height: 50px;
  position: relative; }
  .avatar-container .avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%; }
  .avatar-container .verified-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px; }
