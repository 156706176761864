.loginBox {
  margin-top: 162px;
  background: rgba(255, 255, 255, 0.98);
  padding: 35px 45px 15px 45px;
  border-radius: 8px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 420px;
}
.loginBox .top {
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin-bottom: 16px;
}
.loginBox .top img {
  display: inline-block;
  height: 48px;
  vertical-align: top;
  margin-right: 6px;
}
.loginBox .top h1 {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.8);
}
.loginBox .login {
  margin: 0 auto;
}
