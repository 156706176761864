html, body,
:global(#app) {
  height: 100%; }

:global(.ant-layout) {
  min-height: 100%; }

canvas {
  display: block; }

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a:focus {
  text-decoration: none; }
