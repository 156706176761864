.container {
  height: 100vh;
  background: url('../assets/login_bg.jpeg') no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
}
.container .content {
  flex: 1;
  margin: 0 auto;
}
